import { createSlice } from '@reduxjs/toolkit';
import { createCouponAPI, deleteCouponAPI, getCouponListAPI, retrieveCouponAPI, updateCouponAPI } from 'src/service/CouponService';



const initialState = {
    allCoupons: [],
};

export const CouponReducer = createSlice({
    name: 'Coupon',
    initialState,
    reducers: {
        setAllCoupons: (state, action) => {
            state.allCoupons = action.payload;
        },
        addCoupon: (state, action) => {
            state.allCoupons = [action.payload, ...state.allCoupons];
        },
        updateCoupon: (state, action) => {
            // Below code is to update the question in the array of questions
            const index = state.allCoupons.findIndex((obj) => obj.id === action.payload?.data?.id);
            state.allCoupons = [...state.allCoupons.slice(0, index), action.payload?.data, ...state.allCoupons.slice(index + 1)];
        },
    },
});

export const {
    setAllCoupons,
    addCoupon,
    updateCoupon
} = CouponReducer.actions;


export const getCouponListAction = (param) => async (dispatch) => getCouponListAPI(param);

export const retrieveCouponAction = (id) => async (dispatch) => retrieveCouponAPI(id);

export const createCouponAction = (param) => async (dispatch) => createCouponAPI(param);

export const updateCouponAction = (param) => async (dispatch) => updateCouponAPI(param);

export const deleteCouponAction = (id) => async (dispatch) => deleteCouponAPI(id);

export default CouponReducer.reducer;

