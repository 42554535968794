import { CssBaseline, ThemeProvider } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import ScrollToTop from "./components/shared/ScrollToTop";
import { requestNotificationPermission } from "./firebase";
import RTL from "./layouts/full/shared/customizer/RTL";
import Router from "./routes/Router";
import { ThemeSettings } from "./theme/Theme";

function App() {
    const theme = ThemeSettings();
    const customizer = useSelector((state) => state.customizer);
    const routing = useRoutes(Router);

    let userData;
    try {
        userData = JSON.parse(window.localStorage.getItem("userData")) || {};
    } catch (error) {
        console.error("Error parsing userData from localStorage:", error);
        userData = {};
    }

    // Set default Axios authorization header if userData contains an access token
    if (userData?.access) {
        axios.defaults.headers.common.Authorization = `Bearer ${userData.access}`;
    } else {
        delete axios.defaults.headers.common.Authorization;
    }

    // Generate FCM Token when the app starts
    useEffect(() => {
        requestNotificationPermission();

        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register("/firebase-messaging-sw.js")
                .then((registration) => {
                    console.log("✅ Service Worker Registered:", registration);
                })
                .catch((error) => {
                    console.error("❌ Service Worker Registration Failed:", error);
                });
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <RTL direction={customizer.activeDir}>
                <CssBaseline />
                <ScrollToTop>{routing}</ScrollToTop>
            </RTL>
        </ThemeProvider>
    );
}

export default App;
