import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { fcmTokenRegister } from "./service/AdminFunctionService";

// Firebase Configuration
const firebaseConfig = {
    apiKey: "AIzaSyC8umQzTrNJrvGdQ4EmyH5qrEfGEPw6EIU",
    authDomain: "shaurya-4cccf.firebaseapp.com",
    projectId: "shaurya-4cccf",
    storageBucket: "shaurya-4cccf.appspot.com",
    messagingSenderId: "266548748807",
    appId: "1:266548748807:web:553a085897d2eb778680a2",
    measurementId: "G-8LTHPFYF3W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Function to detect device type
const getDeviceType = () => {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        return "ios";
    } else if (/Android/i.test(navigator.userAgent)) {
        return "android";
    } else {
        return "browser";
    }
};

// Function to Send Token to Backend
const sendTokenToBackend = async (token) => {
    try {
        const deviceType = getDeviceType(); // Get device type (ios, android, browser)

        const requestBody = {
            token: token,
            device_information: {},
            device_type: deviceType,
        };

        const response = await fcmTokenRegister(requestBody);
        console.log("FCM Token registered with backend:", response.data);
    } catch (error) {
        console.error("Error registering FCM token with backend:", error);
    }
};

// Function to Request Notification Permission & Generate FCM Token
export const requestNotificationPermission = async () => {
    try {
        const permission = await Notification.requestPermission();
        console.log("🔔 Notification permission:", permission);

        if (permission === "granted") {
            const token = await getToken(messaging, {
                vapidKey: "BLKW0Ad39PdQ4SruuMKYUqiUWT12Os0MNRINdEzoF3_v9e7wI44FK7kS93E6hAz00f7dJHo2XMM6MBahxkjYvE8",
            });

            if (token) {
                console.log("FCM Token:", token);
                localStorage.setItem("fcmToken", token); // Store token in local storage

                await sendTokenToBackend(token); // Send token to backend
                return token;
            } else {
                console.error("No FCM token received.");
                return null;
            }
        } else {
            console.warn("Notification permission denied.");
            return null;
        }
    } catch (error) {
        console.error("Error requesting notification permission:", error);
        return null;
    }
};

// Handle Foreground Notifications
onMessage(messaging, (payload) => {
    console.log("Received foreground message:", payload);

    if (!payload.notification) {
        console.error("Notification payload is missing.");
        return;
    }

    const { title, body, image } = payload.notification;

    if (Notification.permission === "granted") {
        new Notification(title || "New Notification", {
            body: body || "You have a new message.",
            icon: image || "/firebase-logo.png"
        });
    } else {
        console.warn("Notification permission not granted.");
    }
});
