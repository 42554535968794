import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };

export const getCouponListAPI = async (url) => {
  const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/coupon/`);
  return response;
};

export const retrieveCouponAPI = async (id) => {
  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/coupon/${id}/`,
    method: 'GET',
    headers: headersList,
  };
  const response = await axios.request(reqOptions);
  return response;
};

export const createCouponAPI = async (param) => {
  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/coupon/`,
    method: 'POST',
    headers: headersList,
    data: JSON.stringify({ ...param }),
  };

  const response = await axios.request(reqOptions);
  return response;
};

export const updateCouponAPI = async (param) => {
  const { id, values } = param;
  let reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/coupon/${id}/`,
    method: 'PATCH',
    headers: headersList,
    data: JSON.stringify({ ...values }),
  };

  return await axios.request(reqOptions);
};

export const deleteCouponAPI = async (param) => {
  const { id } = param;
  let reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/bloomtaxonomy/${id}/`,
    method: 'DELETE',
    headers: headersList,
  };
  return await axios.request(reqOptions);
};
